import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="questionnair"
export default class extends Controller {
  connect() {
    console.log('questionnair-connect')
  }

  set(evt) {
    console.log(evt)
    evt.preventDefault();

    const id = evt.target.id
    const answer_field_id = evt.target.dataset.answerValueId
    const answer_field = document.querySelector(
      `input[data-id="${answer_field_id}"]`
    )
    const value = evt.target.dataset.value

    Array.from(document.querySelectorAll(`.answer-fields-${id} button`))
         .forEach(element => {
      console.log(element)
      element.classList.remove('bg-green-900', 'text-white')
    });

    answer_field.value = value
    evt.target.classList.add('bg-green-900', 'text-white')
  }
}

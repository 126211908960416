import { Turbo } from "@hotwired/turbo-rails"
import Rails from "@rails/ujs"
import 'controllers'

Rails.start()

import '../stylesheets/application.scss'
// import '../stylesheets/gridtable.scss'

document.addEventListener("turbo:load", function() {
  document.querySelector('body').style.display = ''
})

let mode = document?.head?.querySelector('meta[name="mode"]')?.content

if (mode != 'download') {
  setInterval( () => {
    var url = new URL(window.location.href)
    if (url.pathname !== '/') {
      fetch("/ping")
        .then((response) => {
          // console.log(response.status, url.pathname)
          if (response.status !== 200) {
            window.location.href = '/logout'
          }
        })
        .catch((error) => (window.location.href = '/logout'))
    }
  }, 60000 )
}
